import React from 'react'
import { Router } from '@reach/router'
import PageNotFound from '../../pages/404'
import SEO from 'components/SEO'
import ymReachGoal from 'utils/ymReachGoal'

const redirect = url => {
	window.location.href = url
	return null
}

const Redirect = props => {
	const { redirectId } = props
	ymReachGoal('redirect-' + redirectId)

	const rules = {
		smspobeda: 'https://new.smspobeda.ru/auth/register/phone/?partner_id=472',
		test: 'https://example.com',
	}
	const target = rules[redirectId] ? rules[redirectId] : '/'
	setTimeout(() => redirect(target), 500)

	return <SEO title="Переходим..." />
}

const RedirectPage = () => (
	<Router>
		<Redirect path="/r/:redirectId" />
		<PageNotFound path="/r/*" />
	</Router>
)

export default RedirectPage
