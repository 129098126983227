import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Empty.module.scss'
import Content from 'components/Content'
import SEO from 'components/SEO'
import { default as Layout } from 'layouts/Main'
import Button from 'components/Button'
import Icon from 'components/Icon'

const b = bem.pageEmpty(css)

const Empty = props => {
	const { title, text, buttonText, buttonURL } = props
	return (
		<Layout className={b()}>
			<Content>
				<SEO title={`${title} — «Парсик»`} />
				<div className={b('content')}>
					<Icon className={b('image')} type="clouds" />
					<div className={b('text')}>
						<h1 className="mb">{title}</h1>
						<div className="h4 mb-lg" dangerouslySetInnerHTML={{ __html: text }} />
						{buttonText && (
							<Button theme="primary" icon="check" to={buttonURL} isLink>
								{buttonText}
							</Button>
						)}
					</div>
				</div>
			</Content>
		</Layout>
	)
}

Empty.defaultProps = {
	title: 'Страница не найдена',
	text: 'Данная страница не&nbsp;существует или доступна по&nbsp;другому адресу',
	buttonText: 'Перейти на главную',
	buttonURL: '/',
}

Empty.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	buttonText: PropTypes.string,
	buttonURL: PropTypes.string,
}

export default Empty
